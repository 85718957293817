export default function Footer() {
    return (
<footer className="mx-4">
        <div className="py-4 flex justify-center items-center  border-t">Built with
                <svg className="animate-pulse w-5 fill-red-600 mx-1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 256">
                    <path d="M240,94c0,70-103.79,126.66-108.21,129a8,8,0,0,1-7.58,0C119.79,220.66,16,164,16,94A62.07,62.07,0,0,1,78,32c20.65,0,38.73,8.88,50,23.89C139.27,40.88,157.35,32,178,32A62.07,62.07,0,0,1,240,94Z"></path>
                </svg>
                by
            <a href="https://twitter.com/anuraag_saxena" target="_blank" rel="noreferrer" className="font-bold px-1">Anuraag</a> &#38;
            <a href="https://twitter.com/build00r" target="_blank" rel="noreferrer" className="font-bold px-1">Sudipto</a>
        </div>
        </footer>
    )
}
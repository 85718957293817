import * as LitJsSdk from "@lit-protocol/lit-node-client";
import ProdConfig from "../config/ProdConfig";
import DevConfig from "../config/DevConfig";

const config = process.env.REACT_APP_ENV === 'production' ? ProdConfig : DevConfig;
const client = new LitJsSdk.LitNodeClient();
const chain = config.chain.nick;

//Must posess any token in an ERC721 collection 0x6fe8433cd8773b0b1c7ccc4489ad54737ff939f5
const accessControlConditions = [
  {
    contractAddress: config.contractConfig.address,
    standardContractType: 'ERC721',
    chain,
    method: 'balanceOf',
    parameters: [
      ':userAddress'
    ],
    returnValueTest: {
      comparator: '>',
      value: '0'
    }
  }
]


class Lit {
  litNodeClient;

  async connect() {
    await client.connect();
    this.litNodeClient = client;
  }

  async gatedContent(id){
    if (!this.litNodeClient) {
      await this.connect();
    }
    const resourceId = {
      baseUrl: 'http://localhost:3000',
      path: '/demo/community-tools',
      orgId: "",
      role: "",
      extraData: id
    }
    
    const authSig = await LitJsSdk.checkAndSignAuthMessage({ chain });
    await this.saveSigningCondition({ accessControlConditions, chain, authSig, resourceId })
      const jwt = await this.getSignedToken({
        accessControlConditions, chain, authSig, resourceId: resourceId
      })
     // Cookies.set('lit-auth', jwt, { expires: 1 })

    return {
      jwt: jwt
    };

  }

  async encryptFile(file) {
    if (!this.litNodeClient) {
      await this.connect();
    }
    const authSig = await LitJsSdk.checkAndSignAuthMessage({ chain });
    const { encryptedFile, symmetricKey } = await LitJsSdk.encryptFile({ file });

    const encryptedSymmetricKey = await this.litNodeClient.saveEncryptionKey({
      accessControlConditions: accessControlConditions,
      symmetricKey,
      authSig,
      chain,
    });

    return {
      encryptedFile: encryptedFile,
      encryptedSymmetricKey: LitJsSdk.uint8arrayToString(encryptedSymmetricKey, "base16")
    };
  }

  async decryptFile(encryptedFile, encryptedSymmetricKey) {
    if (!this.litNodeClient) {
      await this.connect();
    }

    const authSig = await LitJsSdk.checkAndSignAuthMessage({ chain });
    const symmetricKey = await this.litNodeClient.getEncryptionKey({
        accessControlConditions: accessControlConditions,
        toDecrypt: encryptedSymmetricKey,
        chain,
        authSig
    });

    const decryptedFile = await LitJsSdk.decryptFile({
        file: encryptedFile,
        symmetricKey
    });
    return decryptedFile;
  }
}

export default new Lit();

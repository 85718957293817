import { WebBundlr } from "@bundlr-network/client";
import { ethers } from "ethers";
import ProdConfig from "../config/ProdConfig";
import DevConfig from "../config/DevConfig";
/**
 * Creates a new Bundlr object that will then be used by other
 * utility functions. This is where you set your node address and currency.
 *
 * @returns A reference to a Bundlr object
 */

const config = process.env.REACT_APP_ENV === 'production' ? ProdConfig : DevConfig;

export const GetBundlr = async () => {
		
	const provider = new ethers.providers.Web3Provider(window.ethereum);
	console.log ("provider.getSigner().getAddress()", provider.getSigner().getAddress())
	const bundlr = new WebBundlr(config.chain.bundlrURL, "matic", provider);
	//console.log("config.chain.bundlrURL", config.chain.bundlrURL)
	await bundlr.ready();
//	const a1 =  await provider.getAddress()
//	console.log("await provider.getAddress()", a1)
	return bundlr;
};
export default function Navbar() {
  return (
    <>
      <ul className="flex rounded-full bg-white/90 px-3 text-sm font-medium text-slate-800 shadow-lg shadow-slate-800/5 ring-1 ring-slate-900/5 backdrop-blur">
        <li className={`${(window.location.pathname === "/") ? 'hidden' : ''}`} >
          <a
            className="relative block px-3 py-2 transition hover:text-[#ff006a]"
            href="/"
          >
            Home
          </a>
        </li>
        <li className={`${(window.location.pathname === "/team") ? 'hidden' : ''}`} >
          <a
            className="relative block px-3 py-2 transition hover:text-[#ff006a]"
            href="/team"
          >
            Team
          </a>
        </li>
        <li className={`${(window.location.pathname === "/roadmap") ? 'hidden' : ''}`} >
          <a
            className="relative block px-3 py-2 transition hover:text-[#ff006a]"
            href="/roadmap"
          >
            Roadmap
          </a>
        </li>
        <li className={`${(window.location.pathname === "/community-tools") ? 'hidden' : 'hidden'}`} >
          <a
            className="relative block px-3 py-2 transition hover:text-[#ff006a]"
            href="/community-tools"
          >
            Tools
          </a>
        </li>
        <li className={`${(window.location.pathname === "/store") ? 'hidden' : 'hidden'}`} >
          <a
            className="relative block px-3 py-2 transition hover:text-[#ff006a]"
            href="/store"
          >
            Store
          </a>
        </li>
        <li className={`${(window.location.pathname === "/wall") ? 'hidden' : ''}`} >
          <a
            className="relative block px-3 py-2 transition hover:text-[#ff006a]"
            href="/wall"
          >
            Wall
          </a>
        </li>
        <li className={`${(window.location.pathname === "/post") ? 'hidden' : ''}`} >
          <a
            className="relative block px-3 py-2 transition hover:text-[#ff006a]"
            href="/post"
          >
            Post
          </a>
        </li>
      </ul>
    </>
  )
}
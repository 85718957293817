import { GetBundlr } from "./get-bundlr";
 
/**
 * Funds a Bundlr node the specified amount.
 * Note: The currency (token) used is the currency specified when
 * creating the Bundlr object in the file `get-bundlr.js`.
 *
 * @param {*} withdrawAmount About to fund, value in standard units. Value will automatically
 * be coverted to atomic units.
 * @returns "Node funded" if successful or an error message.
 */
export const Withdraw = async (withdrawAmount) => {
	try {
		// get a refernce to the WebBundlr singleton
		const bundlr = await GetBundlr();
		console.log(`Connected to bundlr.address ${bundlr.address}`);
		console.log("bundlr.currencyConfig", bundlr.currencyConfig)

		//const withdrawAmountAtomic = bundlr.utils.toAtomic(withdrawAmount);
		//const tx = await bundlr.withdrawBalance(withdrawAmountAtomic);
		  // 1. Get current balance
		  const curBalance = await bundlr.getLoadedBalance();
		  // 2. Withdraw all
		console.log("curBalance", curBalance);
		  const tx = await bundlr.withdrawBalance(curBalance);
		 
		return { success: true, message: "Balance Withdrawn", transaction: tx };
	} catch (e) {
		console.log("Error on withdraw ", e);
		return { success: false, message: "Error on withdraw", error: e };
	}
};
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Tools from "./pages/Tools";
import Mint from "./pages/Mint";
import Store from "./pages/Store";
import Details from "./pages/Details";
import Roadmap from "./pages/Roadmap";
import Team from "./pages/Team";
import Post from "./pages/Post";
import Poster from "./pages/Poster";
import PageNotFound from "./pages/PageNotFounnd";
import Wall from "./pages/Wall";

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Mint />} />
        <Route path="/community-tools" element={<Tools />} />
        <Route path="/store" element={<Store />} />
        <Route path="/store/:contentId" element={<Details />} />
        <Route path="/roadmap" element={<Roadmap />} />
        <Route path="/team" element={<Team />} />
        <Route path="/wall" element={<Wall />} />
        <Route path="/post" element={<Post />} />
        <Route path="/wall/:postID" element={<Poster />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

export default function Faqs() {
    return (
        <>

            <svg className="max-w-full" height="130" viewBox="0 0 1428 130" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M-2 0C88.7283 0.927528 145.913 27.1879 289.91 59.9119C385.908 81.7279 541.605 89.3348 757 82.7326C467.336 156.254 214.336 153.668 -2 74.9733" fill="#7dd3fc" />
                <path d="M98 104.708C275.413 72.2346 424.148 52.5247 544.204 45.5787C664.259 38.6328 808.525 41.7979 977 55.0742C929.07 56.1225 808.303 74.8455 614.7 111.243C421.097 147.641 248.863 145.463 98 104.708Z" fill="#38bdf8" />
                <path d="M1044 51.6521C1128.83 29.3288 1277.08 17.6079 1437 40.1657V120C1269.17 77.9435 1138.17 55.1609 1044 51.6521Z" fill="#0ea5e9" />
                <path d="M-3.543 66.035C53.086 85.198 94.208 97.809 119.822 103.865C177.454 117.495 230.295 122.29 268.033 125.459C307.355 128.759 392.635 127.801 457.025 123.663C482.76 122.01 514.727 118.372 552.926 112.752C591.747 106.596 618.372 102.008 632.799 98.991C659.913 93.324 708.501 81.503 723.605 78.128C776.47 66.317 814.839 54.532 852.324 47.904C918.689 36.169 951.676 34.522 1007.19 32.432C1056.71 33.477 1093.39 35.129 1117.24 37.387C1157.7 41.219 1204.62 49.821 1231.4 54.304C1281.85 62.748 1350.35 79.432 1436.89 104.354L1437.19 136.352L-2.879 136.031L-3.543 66.035Z" fill="white" />
            </svg>
            <div className="px-4 py-4 sm:px-6">
                <div className="max-w-3xl my-4 sm:my-8 mx-auto w-full">
                    <h2
                        className="mb-6 text-3xl font-extrabold tracking-tight text-center text-slate-900 lg:mb-8 lg:text-3xl">
                        Frequently asked questions</h2>
                    <div className="max-w-screen-md mx-auto text-lg">
                        <div>
                            <h3>
                                <button type="button"
                                    className="flex items-center justify-between w-full py-5 font-medium text-left text-slate-900 border-b border-slate-200">
                                    <span>What is the contract address ?</span>

                                </button>
                            </h3>
                            <div className="">
                                <div className="py-5 border-b border-slate-200">
                                    <p className="mb-2 text-slate-500 break-words">
                                    <a target="_blank" rel="noreferrer" href="https://polygonscan.com/address/0x4987797b94f247235f223cd4ea19e45d0a9700c6">0x4987797b94f247235f223cd4ea19e45d0a9700c6</a></p>
                                </div>
                            </div>
                            <h3>
                                <button type="button"
                                    className="flex items-center justify-between w-full py-5 font-medium text-left text-slate-900 border-b border-slate-200">
                                    <span>How many total cryptoshers can be minted?</span>

                                </button>
                            </h3>
                            <div className="">
                                <div className="py-5 border-b border-slate-200">
                                    <p className="mb-2 text-slate-500">5,555 cryptoshers can be minted
                                        by
                                        the contract. 5,000 are available for public mint.

                                    </p>
                                </div>
                            </div>
                            <h3>
                                <button type="button"
                                    className="flex items-center justify-between w-full py-5 font-medium text-left text-slate-900 border-b border-slate-200">
                                    <span>What does it cost to mint a cryptosher NFT?</span>

                                </button>
                            </h3>
                            <div className="">
                                <div className="py-5 border-b border-slate-200">
                                    <p className="mb-2 text-slate-500">
                                        5 Matic + gas.
                                    </p>
                                </div>
                            </div>
                            <h3>
                                <button type="button"
                                    className="flex items-center justify-between w-full py-5 font-medium text-left text-slate-900 border-b border-slate-200">
                                    <span>How are the cryptoshers generated?</span>

                                </button>
                            </h3>
                            <div className="">
                                <div className="py-5 border-b border-slate-200">
                                    <p className="mb-2 text-slate-500">
                                        Each cryptosher is randomly generated based on a preset collection of words
                                        and
                                        poem structure.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default function PageNotFound() {
  return (

    <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
      <h1 className="font-carter text-[#ff006a] leading-relaxed font-bold text-3xl text-center">
      Cryptosher 
                        </h1>  
        <p className="text-xl font-semibold text-[#ff006a]">404</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-slate-900 sm:text-5xl">Page not found</h1>
        <p className="mt-6 text-base leading-7 text-slate-600">Oops, looks like you've wandered into the poetic void!</p>
        <div className="mt-10 flex items-center justify-center">
          <a href="/" className="bg-[#0e76fd] mx-auto lg:mx-0 mx-auto lg:mx-0 hover:none text-white font-semibold rounded-md py-2 px-4 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">Go back home</a>
        </div>
      </div>
    </main>

  );
}

import { useState, useEffect } from "react";
//import ApolloClient from "apollo-boost";
import { useApolloClient } from "@apollo/client";
import { useQuery, gql } from "@apollo/client";

import { GET_PNG } from "../queries/queries.js";
import { GET_PNG_10 } from "../queries/queries.js";
import Header from "../components/Header";
import { Link } from "react-router-dom";

const Wall = () => {
  const [message, setMessage] = useState("");
  const [numImages, setNumImages] = useState(10);
  const [images, setImages] = useState([]);
  const client = useApolloClient();

  // called on page load (component render)
  const { loading, error, data } = useQuery(GET_PNG_10);

  // load the initial 10
  useEffect(() => {
    /*  if (!loading) {
        const edges = data.transactions.edges;
        const allUrls = [];
        for (let i = 0; i < edges.length; i++) {
          allUrls.push("https://arweave.net/" + edges[i].node.id);
        }
        setImages(allUrls);
      }
      else {
        doQuery();
      }*/
    doQuery();
  }, [numImages]);

  // called when the user clicks "query"
  const doQuery = async () => {
    // clear existing images
    setImages([]);
    // get new ones
    try {
      setMessage("Starting query");
      // Call parseInt on the numImages variable, otherwise JS thinks it's a string
      console.log("Starting query numImages=", numImages);
      const response = await client.query({
        query: GET_PNG,
        variables: { numImages: parseInt(numImages) },
      });
      setMessage("Query done");

      // pull out the transaction ids to use in img tags
      const edges = response.data.transactions.edges;
      const allUrls = [];
      for (let i = 0; i < edges.length; i++) {
        allUrls.push("https://arweave.net/" + edges[i].node.id);
      }
      setImages(allUrls);
    } catch (e) {
      console.error("Query error:", e);
      setMessage("Query error ", e);
    }
  };

  return (
    <div className="bg-white max-w-6xl w-11/12 mb-6 mx-auto">
      <Header title="" subtitle="Wall" />
      <p className="hidden text-sm text-slate-600">
        {
          "Cryptosher – where poetry meets programming, and code flows like rhymes."
        }
      </p>

      <div className="pt-10" id="title-area">
        <div className="pb-4 ml-2 mr-2" id="query-configurer">
          <span className="flex flex-col">
            <label className="font-bold">
              Number of images to retrieve: {numImages}{" "}
            </label>
            <input
              id="default-range"
              type="range"
              min="5"
              max="100"
              value={numImages}
              onChange={(e) => setNumImages(e.target.value)}
              className="mt-2 w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
            />
          </span>
        </div>
        <div className="flex flex-col"></div>
        <div
          className="flex flex-wrap ml-2 mr-2"
          id="query-results"
        >
          {images.map((image, id) => {
            return (
              <a
                href={image}
                target="_blank"
                className="underline"
              >
                {/* <Link to={`${image}`.replace('https://arweave.net/', '')}>  */}

                <img
                  className="mx-1 my-1"
                  width="200"
                  height="200"
                  src={image}
                  key={id}
                />
                {/* </Link> */}

              </a>

            );
          })}
        </div>
      </div>


    </div>

  );
};

export default Wall;

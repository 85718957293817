import contractInterface from "../utils/cryptosher.json"
import {
  polygon
} from 'wagmi/chains';
const ProdConfig = {
    chain: {
      name: polygon,
      rpcUrl: "https://rpc-mainnet.maticvigil.com",
      blockExplorerURL: "https://polygonscan.com",
      openseaURL: "https://opensea.io/assets/matic",
      openseaCollection: "https://opensea.io/collection",
      bundlrURL: "https://node2.bundlr.network",
      nick: "polygon",
      id: 137
      // other Polygon-specific details
  },
  contractConfig: {
    address: '0x4987797B94f247235f223CD4ea19e45d0A9700c6',
    abi: contractInterface,
    price: "5",
  }
  };
  
  export default ProdConfig;
import { ConnectButton } from "@rainbow-me/rainbowkit";
import DevConfig from "../config/DevConfig";
import ProdConfig from "../config/ProdConfig";

const config =
  process.env.REACT_APP_ENV === "production" ? ProdConfig : DevConfig;

const Wallet = ({ title }) => {
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted && authenticationStatus !== "loading";
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === "authenticated");
        return (
          <div
            {...(!ready && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <>
                    {
                      (title === 'Connect to Mint') ?

                        <button onClick={openConnectModal} type="button" className="w-[350px] bg-[#8247E5] text-white font-bold py-2 px-4 rounded-xl inline-flex items-center justify-between shadow-lg hover:none focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                          <span> </span>
                          <span>{title}</span>
                          <span className="flex space-x-2">
                            <span className="h-5 w-px bg-white"></span>
                            <svg className="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path
                              d="M681.469 402.456C669.189 395.312 653.224 395.312 639.716 402.456L543.928 457.228L478.842 492.949L383.055 547.721C370.774 554.865 354.81 554.865 341.301 547.721L265.162 504.856C252.882 497.712 244.286 484.614 244.286 470.325V385.786C244.286 371.498 251.654 358.4 265.162 351.256L340.073 309.581C352.353 302.437 368.318 302.437 381.827 309.581L456.737 351.256C469.018 358.4 477.614 371.498 477.614 385.786V440.558L542.7 403.646V348.874C542.7 334.586 535.332 321.488 521.824 314.344L383.055 235.758C370.774 228.614 354.81 228.614 341.301 235.758L200.076 314.344C186.567 321.488 179.199 334.586 179.199 348.874V507.237C179.199 521.525 186.567 534.623 200.076 541.767L341.301 620.353C353.582 627.498 369.546 627.498 383.055 620.353L478.842 566.772L543.928 529.86L639.716 476.279C651.996 469.135 667.961 469.135 681.469 476.279L756.38 517.953C768.66 525.098 777.257 538.195 777.257 552.484V637.023C777.257 651.312 769.888 664.409 756.38 671.553L681.469 714.419C669.189 721.563 653.224 721.563 639.716 714.419L564.805 672.744C552.525 665.6 543.928 652.502 543.928 638.214V583.442L478.842 620.353V675.125C478.842 689.414 486.21 702.512 499.719 709.656L640.944 788.242C653.224 795.386 669.189 795.386 682.697 788.242L823.922 709.656C836.203 702.512 844.799 689.414 844.799 675.125V516.763C844.799 502.474 837.431 489.377 823.922 482.232L681.469 402.456Z"

                            /></svg>
                            <span>5 Matic</span>
                          </span>
                        </button>
                        :
                        <button onClick={openConnectModal} type="button" className="bg-[#8247E5] text-white font-bold py-2 px-4 rounded-xl inline-flex items-center shadow-lg hover:none focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                          <span>{title}</span>
                        </button>
                    }
                  </>

                );
              }
              if (chain.unsupported) {
                return (
                  <button className="w-[350px] bg-[#FF494A] mx-auto lg:mx-0 hover:none text-white font-semibold rounded-md py-2 px-4 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out" onClick={openChainModal} type="button">
                    Wrong network
                  </button>
                );
              }
              return (
                <div style={{ display: "flex", gap: 12 }}>
                    {/*<button className="hidden inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-slate-600 shadow-sm ring-1 ring-inset ring-slate-900/10 hover:bg-slate-50 dark:bg-slate-800 dark:text-slate-200 dark:ring-inset dark:ring-white/10 dark:hover:ring-white/20 dark:hover:bg-white/5"
                    onClick={openChainModal}
                   // style={{ display: "flex", alignItems: "center" }}
                    type="button"
                  >
                    {chain.hasIcon && (
                      <div
                        style={{
                          background: chain.iconBackground,
                          width: 12,
                          height: 12,
                          borderRadius: 999,
                          overflow: "hidden",
                          marginRight: 4,
                        }}
                      >
                        {chain.iconUrl && (
                          <img
                            alt={chain.name ?? "Chain icon"}
                            src={chain.iconUrl}
                            style={{ width: 12, height: 12 }}
                          />
                        )}
                      </div>
                    )}
                    {chain.name}
                  </button>
                <button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-slate-600 shadow-sm ring-1 ring-inset ring-slate-900/10 hover:bg-slate-50 dark:bg-slate-800 dark:text-slate-200 dark:ring-inset dark:ring-white/10 dark:hover:ring-white/20 dark:hover:bg-white/5" onClick={openAccountModal} type="button">
                  {chain.iconUrl && (
                          <img
                            alt={chain.name ?? "Chain icon"}
                            src={chain.iconUrl}
                            className="h-5 w-5" 
                          />
                        )}
                    {account.displayName}
                    {account.displayBalance
                      ? ` (${account.displayBalance})`
                      : ""}
                    </button>*/}
                  <button onClick={openAccountModal}
                    className="w-[350px] flex items-center justify-between font-bold text-base rounded-xl shadow-[0px_4px_12px_rgba(17,17,26,0.1),_0px_8px_32px_rgba(17,17,26,0.1),_0px_2px_6px_rgba(17,17,26,0.1)]"
                    type="button"
                  >
                    <div className="flex">
                      <div className="w-6 h-6 rounded-full overflow-hidden mr-1 ml-3">
                        {chain.iconUrl && (
                          <img
                            alt={chain.name ?? "Chain icon"}
                            src={chain.iconUrl}
                          />
                        )}
                      </div>
                      <div>{chain.name}</div>
                    </div>
                    <div className="ml-3 bg-[#3c42421a] rounded-xl  border-white border-2 py-2 px-3">
                      {account.displayName}
                    </div>
                  </button>
                  {/*
                  <button className="hidden w-[350px] bg-[#8247E5] text-white font-bold py-2 px-4 rounded-xl inline-flex items-center justify-between shadow-lg hover:none focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
                    <>
                      <span> </span>
                      <span>Mint NFT</span>
                      <span className="flex space-x-2">
                        <span className="h-5 w-px bg-white"></span>
                        <svg className="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024"><path
                          d="M681.469 402.456C669.189 395.312 653.224 395.312 639.716 402.456L543.928 457.228L478.842 492.949L383.055 547.721C370.774 554.865 354.81 554.865 341.301 547.721L265.162 504.856C252.882 497.712 244.286 484.614 244.286 470.325V385.786C244.286 371.498 251.654 358.4 265.162 351.256L340.073 309.581C352.353 302.437 368.318 302.437 381.827 309.581L456.737 351.256C469.018 358.4 477.614 371.498 477.614 385.786V440.558L542.7 403.646V348.874C542.7 334.586 535.332 321.488 521.824 314.344L383.055 235.758C370.774 228.614 354.81 228.614 341.301 235.758L200.076 314.344C186.567 321.488 179.199 334.586 179.199 348.874V507.237C179.199 521.525 186.567 534.623 200.076 541.767L341.301 620.353C353.582 627.498 369.546 627.498 383.055 620.353L478.842 566.772L543.928 529.86L639.716 476.279C651.996 469.135 667.961 469.135 681.469 476.279L756.38 517.953C768.66 525.098 777.257 538.195 777.257 552.484V637.023C777.257 651.312 769.888 664.409 756.38 671.553L681.469 714.419C669.189 721.563 653.224 721.563 639.716 714.419L564.805 672.744C552.525 665.6 543.928 652.502 543.928 638.214V583.442L478.842 620.353V675.125C478.842 689.414 486.21 702.512 499.719 709.656L640.944 788.242C653.224 795.386 669.189 795.386 682.697 788.242L823.922 709.656C836.203 702.512 844.799 689.414 844.799 675.125V516.763C844.799 502.474 837.431 489.377 823.922 482.232L681.469 402.456Z"
                        /></svg>
                        <span> {config.contractConfig.price} Matic</span>
                      </span>
                    </>
                  </button>
                  */}
                </div>
              );
            })()}
            {/*
            <button
              type="button"
              className="hidden inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-slate-600 shadow-sm ring-1 ring-inset ring-slate-900/10 hover:bg-slate-50 dark:bg-slate-800 dark:text-slate-200 dark:ring-inset dark:ring-white/10 dark:hover:ring-white/20 dark:hover:bg-white/5"
              id="menu-button"
              aria-expanded="true"
              aria-haspopup="true"
            >
              <svg
                className="fill-gray-700 dark:fill-gray-400 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 256 256"
              >
                <path d="M251,123.13c-.37-.81-9.13-20.26-28.48-39.61C196.63,57.67,164,44,128,44S59.37,57.67,33.51,83.52C14.16,102.87,5.4,122.32,5,123.13a12.08,12.08,0,0,0,0,9.75c.37.82,9.13,20.26,28.49,39.61C59.37,198.34,92,212,128,212s68.63-13.66,94.48-39.51c19.36-19.35,28.12-38.79,28.49-39.61A12.08,12.08,0,0,0,251,123.13Zm-46.06,33C183.47,177.27,157.59,188,128,188s-55.47-10.73-76.91-31.88A130.36,130.36,0,0,1,29.52,128,130.45,130.45,0,0,1,51.09,99.89C72.54,78.73,98.41,68,128,68s55.46,10.73,76.91,31.89A130.36,130.36,0,0,1,226.48,128,130.45,130.45,0,0,1,204.91,156.12ZM128,84a44,44,0,1,0,44,44A44.05,44.05,0,0,0,128,84Zm0,64a20,20,0,1,1,20-20A20,20,0,0,1,128,148Z"></path>
              </svg>
              <span>Add to Watchlist</span>
            </button> */}


          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};

export default Wallet;

import { ConnectButton } from '@rainbow-me/rainbowkit';
import Header from '../components/Header';
import Instruction from '../components/Instruction';
import { useState, useEffect, useCallback } from 'react';
import DropZone from '../components/DropZone';
import Completed from '../components/Completed';
import prettyBytes from 'pretty-bytes';
import lit from '../utils/lit';
import { UploadImage } from "../utils/upload-image";
import { SupabaseClient } from "../config/SupabaseClient";
import { GetBalanceMatic } from "../utils/get-balance-matic";
import { FundNode } from "../utils/fund-node";
import { Withdraw } from "../utils/withdraw";
import contractInterface from "../utils/cryptosher.json";
import {
    useAccount,
    useContractRead, useProvider, useSigner
} from "wagmi";
import { WebBundlr } from "@bundlr-network/client";
  
const Tools = () => {
    const { isConnected, address } = useAccount();
    const [message, setMessage] = useState("");
    const [txActive, setTxActive] = useState(false);
    const [fileToUpload, setFileToUpload] = useState(null);
    const [fileType, setFileType] = useState();
    // const [uploadedURL, setUploadedURL] = useState("");
    const [encryptedFile, setEncryptedFile] = useState(null);
    const [encryptedSymmetricKey, setEncryptedSymmetricKey] = useState(null);
    const [decryptedFile, setDecryptedFile] = useState(null);
    const [txId, setTxid] = useState(null);
    const [txError, setTxerror] = useState(null);
    const [skip, setSkip] = useState(0);
    const [curBalance, setCurBalance] = useState(0);
    const [fundAmount, setFundAmount] = useState(1);

    const [content, setContent] = useState({
        filename: "",
        description: "",
        location: "",
        size: "",
        file_type: "",
        file_ext: "",
        key:""
    })

    //const noAuthError = "You should have at least 1 Cryptosher NFT to decrypt this file.";
    const rainbowKitProvider = useProvider();
	const { data: rainbowKitSigner, isError, isLoading } = useSigner();


    const contractAddress = "0x6fe8433cd8773b0b1c7ccc4489ad54737ff939f5";
    const contractABI = contractInterface;
  
    const handleIncrement = () => {
        setFundAmount((prevCount) => prevCount + 0.1);
    };

    const handleDecrement = () => {
        if (fundAmount > 1) {
            setFundAmount((prevCount) => prevCount - 0.1);
        }
    };
    //console.log("lit.accessControlConditions", lit.accessControlConditions)
   /* const { data: totalSupplyData } = useContractRead({
        address: contractAddress,
        abi: contractABI,
        functionName: "getTotalNFTsMintedSoFar",
        watch: true,
        cacheTime: 2_000,
        onSuccess(data) {
           console.log('Success', data)
        },
      });

    const { data: tokenCount, isError: error } = useContractRead({
        address: contractAddress,
        abi: contractABI,
        functionName: "balanceOf",
       // watch: true,
       // cacheTime: 2_000,
        args: [address],
        onSuccess(data) {
           console.log('Success', data, tokenCount)
        },
        onError(error) {
            console.log('Error', error)
          },
    });*/
    //console.log("tokenCount", tokenCount, address, totalSupplyData)

    const doFund = async () => {
        if (!fundAmount) {
            setTxerror("Please specify an amount to fund");
            return;
        }
        setMessage(`Funding ${fundAmount} MATIC`);
        const fundStatus = await FundNode(fundAmount);
        fundStatus.success === true ? setMessage(fundStatus.success) : setTxerror(fundStatus.success);
        setCurBalance(await GetBalanceMatic());
        setFundAmount(0);
        setMessage("");
        setSkip(1);
    };

    const doWithdraw = async () => {
        if (!fundAmount) {
            setTxerror("Please specify an amount to withdraw");
            return;
        }
        setMessage(`Withdrawing ${fundAmount} MATIC`);
      
        /*  rainbowKitProvider.getSigner = () => rainbowKitSigner;
		// create a WebBundlr object
		const bundlr = new WebBundlr("https://devnet.bundlr.network", "matic", rainbowKitProvider, {
			providerUrl: "https://rpc-mumbai.matic.today",
		});
 
        await bundlr.ready();
        
        const a1 = await rainbowKitSigner.getAddress()
        console.log("await rainbowKitSigner.getAddress()", a1)
       
       // const a2 = await provider.getAddress()
       // console.log("await provider.getAddress()", a2)
        
        const curBalance = await bundlr.getLoadedBalance();
        console.log("curBalance", curBalance, "bundlr.currencyConfig", bundlr.currencyConfig, "bundlr.address", bundlr.address, "bundlr.currencyConfig.signer.publicKey.toString(hex)", bundlr.currencyConfig.signer.publicKey.toString("hex") );
        //console.log ("provider.getSigner().getAddress()", provider.getSigner().getAddress())
        bundlr.currencyConfig.signer = undefined
        const tx = await bundlr.withdrawBalance(curBalance);

       */
      
         const fundStatus = await Withdraw(fundAmount);
        fundStatus.success === true ? setMessage(fundStatus.success) : setTxerror(fundStatus.success);
        setCurBalance(await GetBalanceMatic());
        setFundAmount(0);
        setMessage("");
    };

    const onDropFile = useCallback(async acceptedFiles => {

        // const supportedFileTypes = ['image/jpeg', 'image/png'];

        // Only return a single file
        const file = acceptedFiles[0];
        console.log("file", file.type)

        setTxerror("");

        //	if (newFiles.length === 0) return;
        setFileToUpload(file);
        setFileType(file.type);
        //setFileSize(prettyBytes(file.size));
        // setContent({ ...content, size: prettyBytes(file.size) });
        setContent((prevalue) => ({
            ...prevalue,   // Spread Operator 
            file_type: file.type,
            size: prettyBytes(file.size),
            file_ext: file.name.split('.').pop()

        }))
        // -- validate:: if file type is .json
        /* if( ! supportedFileTypes.includes(file.type) ){
           setTxerror(`Incorrect file type! We only support ${supportedFileTypes.toString()} at the moment`);
           return;
         }
       */

    }, []);

    /* const handleChange = (event) => {
         setContent((prevalue) => {
             return {
               ...prevalue,   // Spread Operator              
               [event.target.name]: event.target.value 
             }
           })
     }*/

    const handleChange = (event) => {
        let value = event.target.value;
        let name = event.target.name;
        /* not working */
        setContent((prevalue) => ({
            ...prevalue,   // Spread Operator              
            [name]: value
        }))
    }

    const encryptFile = async () => {
        setTxerror("");
        if (content.filename === null || content.filename === '') {
            setTxerror("Content Name cannot be blank!",);
            return;
        }
        if (fileToUpload === null) {
            setTxerror("Please select a file before encrypting!");
            return;
        }
        const { encryptedFile, encryptedSymmetricKey } = await lit.encryptFile(fileToUpload);
        setEncryptedFile(encryptedFile);
        setEncryptedSymmetricKey(encryptedSymmetricKey);
        //setFileSize(0);
        setContent((prevalue) => ({
            ...prevalue,   // Spread Operator 
            key: encryptedSymmetricKey
        }))
    }

   /* const decryptFile = async () => {
        setTxerror('')
        if (encryptedFile === null) {
            setTxerror("Please encrypt your file first!");
            return;
        }
        try {
            console.log("content.location", content.location, txId)
            let response = await fetch(content.location);
            let data = await response.blob();
            console.log("fileType", fileType)
            let metadata = {
                type: fileType
            };
            let file = new File([data], "test.jpg", metadata);
            const decrypted = await lit.decryptFile(file, encryptedSymmetricKey);
            //setFileSize(decrypted.byteLength);
            setContent({ ...content, size: decrypted.byteLength });
            setDecryptedFile(URL.createObjectURL(new File([decrypted], "decrypted.png", { type: fileType })))
            console.log(URL.createObjectURL(new File([decrypted], "decrypted.png", { type: fileType })))
            console.log(decrypted)

        } catch (error) {
            console.log(error)
            setTxerror(noAuthError);
        }
    }*/

    const onClickSignAndUpload = async () => {
        setTxActive(true);
        setTxerror("");
        /*if (!rainbowKitSigner) {
          setMessage("Please connect your wallet first.");
          return;
        }*/
        if (!encryptedFile) {
            setTxerror("Please select a file first.");
            return;
        }
        if (content.filename === null || content.filename === '') {
            setTxerror("Content Name cannot be blank!",);
            return;
        }

        if (encryptedFile) {
            // image post
            // STEP 1: Upload image
            setMessage("Uploading content ....");
            const fileUrl = await UploadImage(encryptedFile, fileType);
            //setUploadedURL("https://arweave.net/" + fileUrl);
            /* not working */
            setContent((prevalue) => ({
                ...prevalue,   // Spread Operator              
                location: `http://arweave.net/${fileUrl}`
            }))
            setTxid(fileUrl)
            //  setContent({ ...content, location: `http://arweave.net/${txId}` });      
            // setFileToUpload(null);
            // setFileType("");
            if (fileUrl) {
                console.log(content, "before insert")
                const { error } = await SupabaseClient
                    .from('cs_content')
                    .insert({
                        name: content.filename,
                        description: content.description,
                        location: fileUrl,
                        size: content.size,
                        uploader: address,
                        file_type: content.file_type,
                        file_ext: content.file_ext,
                        file_key: content.key
                    })
            }
        }
        setMessage('')
        setTxActive(false);
    }
    useEffect(() => {

    const fetchBalance = async () => {
        setCurBalance(await GetBalanceMatic());
    };
        fetchBalance();
    }, [content, isConnected]);
    return (
            <div className="bg-white max-w-6xl w-11/12 mb-6 mx-auto">
        {/* <div className="bg-white max-w-[38em] w-[88%] mb-6 mx-auto"> */}


            <Header
                title="A member-only tool to share content with the community" subtitle="Community Tools "
            />

            <h2 className="text-slate-900 block text-[1.5em] font-bold py-2 my-3 border-slate-900 border-b-[0.5px] border-dashed">Prerequisite</h2>
            <div className="text-sm text-slate-500">You will need to be a Cryptosher holder already, if not mint a Cryptosher <a className="text-slate-900 border-b-slate-900 border-b border-solid" target="_blank" rel="noreferrer" href="/">here</a></div>
            <div className="hidden text-sm text-slate-500 pb-2 mb-3">2. You will need to fund the node, more info <a className="text-slate-900 border-b-slate-900 border-b border-solid" target="_blank" rel="noreferrer" href="https://docs.bundlr.network/docs">here</a></div>
            <Instruction title='1. Connect wallet and switch to Polygon Network'
                subtitle='Connect to the wallet that holds atleast 1 Cryptosher NFT. Make sure you are on the Polygon Network.'
            />
            <ConnectButton />
            {
                (!isConnected) ? '' :
                    <>
                        <Instruction title='2. Fund node up-front'
                            subtitle='You can up-front fund a node, where you send over enough funds to cover all future uploads.'
                        />
                        <div className="flex my-3" >
                            <button className="flex items-center justify-center font-bold text-base rounded-xl shadow-[0px_4px_12px_rgba(17,17,26,0.1),_0px_8px_32px_rgba(17,17,26,0.1),_0px_2px_6px_rgba(17,17,26,0.1)]" type="button">
                                <div className="w-6 h-6 rounded-full overflow-hidden mr-1 ml-3">
                                    <img
                                        alt="bundlr"
                                        src="/bundlr.svg"
                                        className="h-6 w-6"
                                    />
                                </div>
                                <div>
                                    {"Bundlr Network "}
                                </div>
                                <div className="ml-3 bg-[#3c42421a] rounded-xl  border-white border-2 py-2 px-3">
                                    {curBalance}
                                </div>
                            </button>
                        </div>
                        <div className="text-slate-900 my-8 ">
                            <div>Add funds</div>
                        <div className="mt-3 space-y-5">
                            <div className="flex rounded-md p-0 m-0">
                                <input value={fundAmount.toFixed(1)} onChange={(e) => setFundAmount(parseFloat(e.target.value))} type="number" name="bal" inputmode="numeric" min="1" step=".01" className="px-3 block  rounded-l-md border-0 py-1.5 text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-slate-300 text-sm sm:leading-6" placeholder="Fund node up-front" />
                                <div className='flex flex-col p-0 m-0'>
                                    <button onClick={handleIncrement} className="h-1/2 w-6 rounded-tr-md ring-1 ring-inset ring-slate-300 mx-auto lg:mx-0 hover:none font-semibold shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">{'+'}</button>
                                    <button onClick={handleDecrement} className="h-1/2 w-6 rounded-br-md ring-1 ring-inset ring-slate-300 mx-auto lg:mx-0 hover:none font-semibold shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">{'-'}</button>
                                </div>
                            </div>
                            </div>

                            <div className="my-8 space-x-3">
                                <button onClick={doFund} className={`${message.includes("und") ? "bg-[#16191f3d]" : "bg-[#0e76fd]"} mx-auto lg:mx-0 mx-auto lg:mx-0 hover:none text-white font-semibold rounded-md py-2 px-4 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"`}>{message.includes("und") ? message : 'Add Funds'}</button>
                                <button onClick={doWithdraw} className={`${message.includes("ithdr") ? "bg-[#16191f3d]" : "bg-[#0e76fd]"} mx-auto lg:mx-0 mx-auto lg:mx-0 hover:none text-white font-semibold rounded-md py-2 px-4 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"`}>{message.includes("ithdr") ? message : 'Withdraw Funds'}</button>
                            </div>

                        </div>
                        <div className="mt-3 text-sm text-slate-500">You can also lazy-fund a node where you check the cost to upload each file first and then transfer exact funds. If you wish to skip up-front funding of node click <span onClick={() => setSkip(1)} className="cursor-pointer text-slate-900 border-b-slate-900 border-b border-solid">here</span></div>

                    </>
            }

            {
                (!isConnected || skip === 0) ? '' :
                    <>
                        <h2 className="text-slate-900 block text-[1.5em] font-bold py-2 my-3 border-slate-900 border-b-[0.5px] border-dashed">Upload content</h2>

                        <Instruction title='1. Select a file you want to upload' subtitle="Please don't upload any content for which you do not hold the necessary rights or permissions. This includes, but is not limited to, files that infringe upon someone else's copyright, trademark, or other intellectual property rights." />

                        <div className="text-slate-900 my-8 ">
                            <div>Name*</div>

                            <div className="mt-3 text-sm text-slate-500">
                                <input type="text" name="filename" onChange={handleChange} className="pl-3 block w-full rounded-md border-0 py-1.5 text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-slate-300 text-sm sm:leading-6" placeholder="Content Name" />
                            </div>

                            <div className="mt-3">
                                Description
                            </div>
                            <div className="mt-3 text-sm text-slate-500">
                                <textarea name="description" rows="3" onChange={handleChange} defaultValue={content.description} className="pl-3 block w-full rounded-md border-0 py-1.5 text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-slate-300 text-sm sm:leading-6" placeholder="Provide a detailed description of your content."></textarea>
                            </div>
                        </div>


                        {
                            (!isConnected || fileToUpload == null)
                                ? <DropZone onDrop={onDropFile} />
                                : <Completed title="Got your file!" subtitle={`Size: ${content.size}`} />
                        }

                        {
                            (!isConnected || !fileToUpload) ? '' :
                                <>
                                    <Instruction title='2. Click to encrypt file' subtitle="The file will be accessible only to wallets containing at least one cryptosher NFT." />

                                    {
                                        (!isConnected || !fileToUpload || !encryptedFile)
                                            ? <button onClick={() => encryptFile()} className="bg-[#0e76fd] mx-auto lg:mx-0 hover:none text-white font-semibold rounded-md py-2 px-4 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">{'Encrypt File'}</button>
                                            : <Completed title="File encrypted!" subtitle="Only community members can decrypt this file." />
                                    }
                                </>
                        }

                        {
                            (!isConnected || !fileToUpload || !encryptedFile) ? ''
                                :
                                <>
                                    <Instruction title='3. Click to sign and upload'
                                        subtitle='The encrypted file will be stored forever with just a single, one-time fee.'
                                    />

                                    {
                                        (!isConnected || !fileToUpload || !encryptedFile || !txId)
                                            ? <button onClick={() => onClickSignAndUpload()} className={`${message ? "bg-[#16191f3d]" : "bg-[#0e76fd]"} mx-auto lg:mx-0 mx-auto lg:mx-0 hover:none text-white font-semibold rounded-md py-2 px-4 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"`}>{message ? message : 'Sign and upload'}</button>
                                            :
                                            <Completed title="Encrypted image uploaded to Arweave!">
                                                View Transaction: <a className="text-slate-900 border-b-slate-900 border-b border-solid" target="_blank" rel="noreferrer" href={`https://arweave.app/tx/${txId}`}>{`https://arweave.app/tx/${txId}`}</a>
                                                <br />
                                                Download Link: <a className="text-slate-900 border-b-slate-900 border-b border-solid" target="_blank" rel="noreferrer" href={`https://arweave.net/${txId}`}>{`https://arweave.net/${txId}`}</a>

                                            </Completed>
                                    }

                                </>
                        }

                        {
                            (!isConnected || !fileToUpload || !encryptedFile || !txId) ? ''
                                :
                                <>
                                    {/* 

                                    <Instruction title='4. Click to decrypt the downloaded encrypted data'
                                        subtitle='We will combine access control conditions, encrypted image data, and encrypted symmetric key as JSON, and turn it into String format'
                                    />

                                    {
                                        (!isConnected || !fileToUpload || !encryptedFile || !txId || !decryptedFile)
                                            ? <button onClick={() => decryptFile()} className="bg-[#0e76fd] mx-auto lg:mx-0 hover:none text-white font-semibold rounded-md py-2 px-4 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">{'Decrypt downloaded encrypted data'}</button>
                                            :
                                            <Completed title="Done. Data decrypted!" subtitle="Encrypted key is stored to lit nodes" >
                                                <br />
                                                {'Click '}<a className="text-slate-900 border-b-slate-900 border-b border-solid" target="_blank" rel="noreferrer" href={decryptedFile}>here</a>{' to download.'}
                                            </Completed>
                                    }
                                */}

                                    <div className="py-5 text-sm text-slate-500">Thank you for sharing valuable content with the community. Please visit the <a className="text-slate-900 border-b-slate-900 border-b border-solid" target="_blank" rel="noreferrer" href="/store">community store</a> to view your uploaded contents, as well as contents uploaded by other community members.</div>

                                </>
                        }

                    </>
                
            }
             {txError && (
                            <p className="pt-5 text-[#FF6257] flex justify-start font-medium">
                                Error: {txError}
                            </p>
                        )}
        </div>
    );
};

export default Tools;

const MainIcon = () => {
    return (
        <div className="flex flex-col justify-center item-center">
                      <h1 className="font-carter text-[#ff006a] leading-relaxed font-bold text-7xl text-center mt-4 py-2 sm:py-4">
            Cryptosher
          </h1>
            {/*<img className="mt-6 py-2 sm:py-4 col-span-2 h-24 w-full object-contain lg:col-span-1" src="./mainlogo.svg" alt="Cryptosher"/>*/}
            <p className="pt-3 w-11/12 mx-auto text-center px-2 font-semimedium text-lg">The first on-chain
                programmatically
                generated Hindi couplets on the Polygon blockchain.
            </p>
        </div>
    );
}

export default MainIcon;
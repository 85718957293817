import DoneIcon from './DoneIcon';

const Completed = ({ title, subtitle, children }) => {
    return (

        <div className="text-slate-900 text-center overflow-auto mt-3 p-2 rounded-lg shadow-[0px_4px_12px_rgba(17,17,26,0.1),_0px_8px_32px_rgba(17,17,26,0.1),_0px_2px_6px_rgba(17,17,26,0.1)]">
            <DoneIcon />
            <div>{title}</div>
            <div className="text-xs">
                {subtitle}
                {children}
            </div>
        </div>
    );
}

export default Completed;
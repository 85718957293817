import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Instruction from "../components/Instruction";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import lit from '../utils/lit';
import { SupabaseClient } from "../config/SupabaseClient";
import { useAccount } from "wagmi";
import Header from '../components/Header'; 
import Completed from '../components/Completed';
import TimeAgo from "timeago-react";

const Details = () => {
  const [decryptedFile, setDecryptedFile] = useState(null);
  const [txError, setTxerror] = useState(null);
  const noAuthError = "You should have at least 1 Cryptosher NFT to decrypt this file.";
  const [contents, setContents] = useState([]);
  const { isConnected, address } = useAccount();

  const { contentId } = useParams();
  const decryptFile = async () => {
    setTxerror('')
    /*if (encryptedFile === null) {
        setTxerror("Please encrypt your file first!");
        return;
    }*/
    try {
      let response = await fetch("https://arweave.net/" + contents.location);
      /*
      const data = await fetch('<https://arweave.net/>' + transactionId);

      const dataOnArweave = JSON.parse(await data.text());

      */
      let data = await response.blob();
      console.log("fileType", contents.file_type)
      let metadata = {
        type: contents.file_type
      };
      let file = new File([data], "test." + contents.file_ext, metadata);
      console.log(file)
      const decrypted = await lit.decryptFile(file, contents.file_key);
      /* const encryptedSymmetricKey = LitJsSdk.uint8arrayToString(dataOnArweave.encryptedSymmetricKey, "base16");     
       const decrypted = await lit.decryptFile(file, encryptedSymmetricKey); */

      setDecryptedFile(URL.createObjectURL(new File([decrypted], "decrypted." + contents.file_ext, { type: contents.file_type })))
      console.log(URL.createObjectURL(new File([decrypted], "decrypted." + contents.file_ext, { type: contents.file_type })))
      console.log(decrypted)

    } catch (error) {
      console.log(error)
      setTxerror(noAuthError);
    }
  }

  useEffect(() => {
    async function getContents() {
      const { data } = await SupabaseClient.from("cs_content")
        .select()
        .eq('id', contentId)
        .single();
      setContents(data);
    }
    getContents();
  }, []);
  return (
    <div className="bg-white max-w-6xl w-11/12 mb-6 mx-auto">

      <Header
        title={""} subtitle="Community Tools"
      />

      <h2 className="text-slate-900 block text-[1.5em] font-bold py-2 my-3 border-slate-900 border-b-[0.5px] border-dashed">{contents.name}</h2>
      <div className="hidden text-sm text-slate-500">{contents.description}</div>


      <div className="mt-6">
        <dl className="divide-y divide-slate-100">
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-slate-900">Description</dt>
            <dd className="mt-1 text-sm leading-6 text-slate-700 sm:col-span-2 sm:mt-0">{contents.description}</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-slate-900">Type</dt>
            <dd className="mt-1 text-sm leading-6 text-slate-700 sm:col-span-2 sm:mt-0">{contents.file_type}</dd>
          </div>
                 <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-slate-900">Size</dt>
            <dd className="mt-1 text-sm leading-6 text-slate-700 sm:col-span-2 sm:mt-0">{contents.size}</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-slate-900">Date</dt>
            <dd className="mt-1 text-sm leading-6 text-slate-700 sm:col-span-2 sm:mt-0"><TimeAgo datetime={contents.inserted_at} /></dd>
          </div>
        </dl>
      </div>

      <Instruction title='1. Connect wallet and switch to Polygon Network'
        subtitle='Connect to the wallet that holds atleast 1 Cryptosher NFT. Make sure you are on the Polygon Network.'
      />
      <ConnectButton />
      {
        (!isConnected) ? ''
          :
          <>


            <Instruction title='2. Click to download'
              subtitle='You should see the download link once the data is decrypted.'
            />

            {
              (!isConnected || !decryptedFile)
                ? <button onClick={() => decryptFile()} className="bg-[#0e76fd] mx-auto lg:mx-0 hover:none text-white font-semibold rounded-md py-2 px-4 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">{'Decrypt and download'}</button>
                :
                <Completed title="Done. Data decrypted!" subtitle={`Encrypted key: ${contents.file_key.slice(0, 2)}*******${contents.file_key.slice(0, 6)}`}>
                  <br />
                  {'Click '}<a className="text-slate-900 border-b-slate-900 border-b border-solid" target="_blank" rel="noreferrer" href={decryptedFile}>here</a>{' to download.'}
                </Completed>
            }

            {txError && (
              <p className="pt-5 text-[#FF6257] flex justify-start font-medium">
                Error: {txError}
              </p>
            )}

          </>
      }

    </div>

  );

}
export default Details;

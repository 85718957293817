export default function Features() {
    return (
        <>
            <svg className="max-w-full" height="130" viewBox="0 0 1428 130" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M-2 0C88.7283 0.927528 145.913 27.1879 289.91 59.9119C385.908 81.7279 541.605 89.3348 757 82.7326C467.336 156.254 214.336 153.668 -2 74.9733" fill="#f472b6" />
                <path d="M98 104.708C275.413 72.2346 424.148 52.5247 544.204 45.5787C664.259 38.6328 808.525 41.7979 977 55.0742C929.07 56.1225 808.303 74.8455 614.7 111.243C421.097 147.641 248.863 145.463 98 104.708Z" fill="#ec4899" />
                <path d="M1044 51.6521C1128.83 29.3288 1277.08 17.6079 1437 40.1657V120C1269.17 77.9435 1138.17 55.1609 1044 51.6521Z" fill="#db2777" />
                <path d="M-3.543 66.035C53.086 85.198 94.208 97.809 119.822 103.865C177.454 117.495 230.295 122.29 268.033 125.459C307.355 128.759 392.635 127.801 457.025 123.663C482.76 122.01 514.727 118.372 552.926 112.752C591.747 106.596 618.372 102.008 632.799 98.991C659.913 93.324 708.501 81.503 723.605 78.128C776.47 66.317 814.839 54.532 852.324 47.904C918.689 36.169 951.676 34.522 1007.19 32.432C1056.71 33.477 1093.39 35.129 1117.24 37.387C1157.7 41.219 1204.62 49.821 1231.4 54.304C1281.85 62.748 1350.35 79.432 1436.89 104.354L1437.19 136.352L-2.879 136.031L-3.543 66.035Z" fill="white" />
            </svg>
            <div className="max-w-3xl my-4 sm:my-8 mx-auto w-full">
                <h2
                    className="mb-6 text-3xl font-extrabold tracking-tight text-center text-slate-900 lg:mb-8 lg:text-3xl">
                    Get your own Cryptosher</h2>
                <div className="flex flex-wrap max-w-3xl mx-auto">

                    <div className="w-full md:w-6/12 px-4">
                        <div className="relative flex flex-col mt-4">
                            <div className="px-4 py-5 flex-auto">
                                <div
                                    className="text-slate-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                                    </svg>
                                </div>
                                <h6 className="text-xl mb-1 font-semibold">The collection</h6>
                                <p className="mb-4 text-slate-500">A collection of 5,555 programmatically generated Hindi couplet NFTs.
                                </p>
                            </div>
                        </div>
                        <div className="relative flex flex-col min-w-0">
                            <div className="px-4 py-5 flex-auto">
                                <div
                                    className="text-slate-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                                    </svg></div>
                                <h6 className="text-xl mb-1 font-semibold">On-chain SVG</h6>
                                <p className="mb-4 text-slate-500">Randomized poetry, with proof of ownership, entirely built on-chain.
                                </p>
                            </div>
                        </div>
                        <div className="relative flex flex-col min-w-0">
                            <div className="px-4 py-5 flex-auto">
                                <div
                                    className="text-slate-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 21v-4m0 0V5a2 2 0 012-2h6.5l1 1H21l-3 6 3 6h-8.5l-1-1H5a2 2 0 00-2 2zm9-13.5V9" />
                                    </svg></div>
                                <h6 className="text-xl mb-1 font-semibold">Mint Milestone</h6>
                                <p className="mb-4 text-slate-500">85% -Giveaway limited edition merch to selected Cryptosher NFT holders.</p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-6/12 px-4">
                        <div className="relative flex flex-col min-w-0 mt-4">
                            <div className="px-4 py-5 flex-auto">
                                <div
                                    className="text-slate-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
                                    </svg>
                                </div>
                                <h6 className="text-xl mb-1 font-semibold">Gulzar-esque poetry </h6>
                                <p className="mb-4 text-slate-500">All cryptosher are beautiful, but some are rarer than others.
                                </p>
                            </div>
                        </div>
                        <div className="relative flex flex-col min-w-0">
                            <div className="px-4 py-5 flex-auto">
                                <div
                                    className="text-slate-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                                    </svg>
                                </div>
                                <h6 className="text-xl mb-1 font-semibold">ERC-721</h6>
                                <p className="mb-4 text-slate-500">The cryptoshers are stored as ERC-721 tokens on the Polygon blockchain.
                                </p>
                            </div>
                            <div className="px-4 py-5 flex-auto">
                                <div
                                    className="text-slate-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                                    </svg>
                                </div>
                                <h6 className="text-xl mb-1 font-semibold">The Team</h6>
                                <p className=" text-slate-500">
                                    Concept by <a href="https://twitter.com/anuraag_saxena" target="_blank" rel="noreferrer">Anuraag Saxena</a>
                                </p>
                                <p className="mb-4 text-slate-500">Built by <a href="https://twitter.com/5ud1pt0" target="_blank" rel="noreferrer">Sudipto</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
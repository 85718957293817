import { Link } from "react-router-dom";
import Header from "../components/Header";
import { useAccount } from "wagmi";
import { SupabaseClient } from "../config/SupabaseClient";
import { useState, useEffect } from "react";
import TimeAgo from "timeago-react";
import React from "react"; 

const Store = () => {
  const { isConnected, address } = useAccount();
  const [contents, setContents] = useState([]);

  const getFileType = (fileExtension) => {
    if (fileExtension?.match(/(mp3|wav|ogg|flac)/)) {
      //return 'Audio';
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 256 256"
          fill="red"
        >
          <path d="M96.59,124.91a12,12,0,0,0-13.08,2.6L63,148H44a12,12,0,0,0-12,12v40a12,12,0,0,0,12,12H63l20.48,20.48A12,12,0,0,0,104,224V136A12,12,0,0,0,96.59,124.91ZM80,195l-3.51-3.52A12,12,0,0,0,68,188H56V172H68a12,12,0,0,0,8.49-3.51L80,165Zm80-15a44.55,44.55,0,0,1-21.95,38.36A12,12,0,1,1,126,197.64a20.51,20.51,0,0,0,0-35.28,12,12,0,1,1,12.1-20.72A44.55,44.55,0,0,1,160,180ZM216.49,79.51l-56-56A12,12,0,0,0,152,20H56A20,20,0,0,0,36,40v76a12,12,0,0,0,24,0V44h76V92a12,12,0,0,0,12,12h48V212H172a12,12,0,0,0,0,24h28a20,20,0,0,0,20-20V88A12,12,0,0,0,216.49,79.51ZM160,57l23,23H160Z"></path>
        </svg>
      );
    } else if (fileExtension?.match(/(mp4|mov|avi|mkv)/)) {
      //return 'Video';
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 256 256"
          fill="blue"
        >
          <path d="M150.35,149.82a12,12,0,0,0-11.63-.6L118,159.37A20,20,0,0,0,100,148H48a20,20,0,0,0-20,20v40a20,20,0,0,0,20,20h52a20,20,0,0,0,18.3-12l20.12,10.58A12,12,0,0,0,156,216V160A12,12,0,0,0,150.35,149.82ZM96,204H52V172H96Zm36-7.87-12-6.3v-4.72l12-5.87ZM216.49,79.51l-56-56A12,12,0,0,0,152,20H56A20,20,0,0,0,36,40v76a12,12,0,0,0,24,0V44h76V92a12,12,0,0,0,12,12h48V212h-8a12,12,0,0,0,0,24h12a20,20,0,0,0,20-20V88A12,12,0,0,0,216.49,79.51ZM160,57l23,23H160Z"></path>
        </svg>
      );
    } else if (fileExtension?.match(/(doc|txt|csv|json)/)) {
      // return 'Text';
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 256 256"
          fill="green"
        >
        <path d="M216.49,79.52l-56-56A12,12,0,0,0,152,20H56A20,20,0,0,0,36,40V216a20,20,0,0,0,20,20H200a20,20,0,0,0,20-20V88A12,12,0,0,0,216.49,79.52ZM160,57l23,23H160ZM60,212V44h76V92a12,12,0,0,0,12,12h48V212Zm112-80a12,12,0,0,1-12,12H96a12,12,0,0,1,0-24h64A12,12,0,0,1,172,132Zm0,40a12,12,0,0,1-12,12H96a12,12,0,0,1,0-24h64A12,12,0,0,1,172,172Z"></path>
        </svg>
      );
    } else if (fileExtension?.match(/(jpg|jpeg|png|gif)/)) {
      // return 'Image';
      return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5"
        viewBox="0 0 256 256"
        fill="currentColor"
      >
    <path d="M114,145.34a12,12,0,0,0-20,0L79,167.82,72.3,157.73a12,12,0,0,0-20.07.17L13.91,217.51A12,12,0,0,0,24,236H152a12,12,0,0,0,10-18.66ZM46,212,62.5,186.3l6.55,9.81a12,12,0,0,0,20,0l15-22.48L129.58,212ZM216.49,79.51l-56-56A12,12,0,0,0,152,20H56A20,20,0,0,0,36,40v84a12,12,0,0,0,24,0V44h76V92a12,12,0,0,0,12,12h48V212a12,12,0,0,0,0,24h4a20,20,0,0,0,20-20V88A12,12,0,0,0,216.49,79.51ZM160,57l23,23H160Z"></path>
      </svg>);
    } else {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 256 256"
          fill="currentColor"
        >
        <path d="M216.49,79.52l-56-56A12,12,0,0,0,152,20H56A20,20,0,0,0,36,40V216a20,20,0,0,0,20,20H200a20,20,0,0,0,20-20V88A12,12,0,0,0,216.49,79.52ZM160,57l23,23H160ZM60,212V44h76V92a12,12,0,0,0,12,12h48V212Z"></path>
        </svg>);
    }
  };

  useEffect(() => {
    async function getContents() {
      const { data } = await SupabaseClient.from("cs_content").select();
      setContents(data);
    }
    getContents();
  }, []);
  return (
    <div className="bg-white max-w-6xl w-11/12 mb-6 mx-auto">
      {/* 
        Hidden in the background, but will appear when you click to 
        set the access control conditions
      
      <div id="shareModal"></div>
*/}

      <Header title="" subtitle="Digital Store" />
      <div className="h-8 w-full items-center gap-2 rounded-full bg-white pl-2 pr-3 text-sm text-slate-600 ring-1 ring-slate-900/10 transition hover:ring-slate-900/20 flex focus:outline-none">
        <svg
          className="h-5 w-5 fill-slate-600 hover:fill-slate-900 dark:fill-slate-200 dark:hover:fill-white"
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 256 256"
        >
          <path d="M229.66,218.34l-50.07-50.06a88.11,88.11,0,1,0-11.31,11.31l50.06,50.07a8,8,0,0,0,11.32-11.32ZM40,112a72,72,0,1,1,72,72A72.08,72.08,0,0,1,40,112Z"></path>
        </svg>
        <input
          type="text"
          className="w-full outline-none bg-transparent text-slate-800 dark:text-white"
          placeholder="Search Products"
          required
        />
      </div>
      {!isConnected ? (
        ""
      ) : (
        <>
          <h2 className="text-slate-900 block text-[1.5em] font-bold py-2 my-3 border-slate-900 border-b-[0.5px] border-dashed">
            {"Download Content"}
          </h2>
          <table className="text-left border-collapse w-full">
            <thead>
              <tr>
                <th className="sticky z-10 top-0 text-sm leading-6 font-semibold text-slate-700 bg-white p-0">
                  <div className="py-2 pl-2 border-b border-slate-200">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="invisible h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                      />
                    </svg>
                  </div>
                </th>
                <th className="sticky z-10 top-0 text-sm leading-6 font-semibold text-slate-700 bg-white p-0">
                  <div className="py-2 pr-2 border-b border-slate-200">Name</div>
                </th>
                <th className="sticky z-10 top-0 text-sm leading-6 font-semibold text-slate-700 bg-white p-0">
                  <div className="py-2 pr-2 border-b border-slate-200">Time</div>
                </th>
                <th className="sticky z-10 top-0 text-sm leading-6 font-semibold text-slate-700 bg-white p-0">
                  <div className="py-2 pl-2 border-b border-slate-200">Size</div>
                </th>
                <th className="sticky z-10 top-0 text-sm leading-6 font-semibold text-slate-700 bg-white p-0">
                  <div className="py-2 pl-2 border-b border-slate-200">
                    Uploader
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="align-baseline">
              {contents.map((content) => (
                <tr key={content.id}>
                  <td className="align-middle	font-mono font-medium text-sm leading-6 text-indigo-400 whitespace-pre">
                    {getFileType(content?.file_ext)}
                  </td>
                  <td className="py-2 px-2 font-mono text-sm leading-6 text-indigo-600">
                    <Link to={`${content.id}`}>
                      {" "}
                      {content.name + content.file_ext}
                    </Link>

                    {/*<a href={decryptedFile} target="_blank" className="flex flex-wrap">
                      {content.name}
                    </a>*/}
                  </td>
                  <td className="py-2 pr-2 font-mono font-medium text-sm leading-6 text-sky-500 whitespace-pre">
                    { }
                    <TimeAgo datetime={content.inserted_at} />
                  </td>
                  <td className="py-2 pr-2 font-mono font-medium text-sm leading-6 text-indigo-400 whitespace-pre">
                    {content.size}
                  </td>
                  <td className="py-2 pr-2 font-mono font-medium text-sm leading-6 text-sky-500 whitespace-pre">
                    {content.uploader?.slice(0, 4)}...
                    {content.uploader?.slice(-4)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}


    </div>
  );
};

export default Store;

import UploadIcon from "./UploadIcon";
import { useDropzone } from 'react-dropzone'

const DropZone = ({ onDrop, text }) => {

  const _text = text ?? 'Click or drop your file here';

  // useDropzone
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div className="cursor-pointer text-slate-900 w-64 justify-center flex flex-col text-center p-3 rounded-lg border-[#AAAAAA] border-2 border-dashed bg-slate-100">
        <div className="w-8 m-auto">
            <UploadIcon />
          </div>
          <div>{_text}</div>
        </div>
      </div>

  );
}

export default DropZone;
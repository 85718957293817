import Header from "../components/Header";
import React, { useState, useRef } from "react";
import html2canvas from "html2canvas";
import { UploadImage } from "../utils/upload-image";
import { useParams } from 'react-router-dom';

const Poster = () => {
  const { postID } = useParams();
  return (
    <div className="bg-white max-w-6xl w-11/12 mb-6 mx-auto">
      <Header title="" subtitle="Poster" />
      <p className="hidden text-sm text-slate-600">
        {
          "Cryptosher – where poetry meets programming, and code flows like rhymes."
        }
      </p>

      <div className="flex justify-center items-center flex transform text-left text-base transition">
        <img
          className="h-screen shadow-lg"
          src={`https://arweave.net/${postID}`}
          alt=""
        />

      </div>


    </div>

  );
};

export default Poster;

import Header from "../components/Header";
import React, { useState, useRef } from "react";
import { UploadImage } from "../utils/upload-image";
import {
  useAccount, useContractRead, useNetwork, useSwitchNetwork
} from "wagmi";
import Wallet from "../components/Wallet";
import DevConfig from "../config/DevConfig";
import ProdConfig from "../config/ProdConfig";

const config =
  process.env.REACT_APP_ENV === "production" ? ProdConfig : DevConfig;

const Post = () => {
  const { isConnected, address } = useAccount();
  const postRef = useRef(null);
  const svgRef = React.createRef();
  const [characterCount, setCharacterCount] = useState(0);
  const [message, setMessage] = useState("");
  const [txError, setTxerror] = useState(null);
  const { chain } = useNetwork()
  const [content, setContent] = useState({
    name: "",
    description: "",
  });

  const { chains, err, isLoading, pendingChainId, switchNetwork } =
    useSwitchNetwork()
  
  const svgStyles = `
    @import url('https://fonts.googleapis.com/css2?family=Carter+One&family=Merriweather&display=swap');
    .base { fill: white; font-family: "Merriweather"; font-size: 20px; }
    .top { fill: white; font-family: "Carter One"; font-size: 20px; }
    `;
  
    const { data: tokenCount, isError: error } = useContractRead({
      address: config.contractConfig.address,
      abi: config.contractConfig.abi,
      functionName: "balanceOf",
     // watch: true,
     // cacheTime: 2_000,
      args: [address],
      onSuccess(data) {
         console.log('Success', data, tokenCount?.toNumber())
      },
      onError(error) {
          console.log('Error', error)
        },
    });
   
 // console.log(tokenCount?.toNumber(), 'tokenCount')
  //const ethers = require('ethers');
  //console.log('ethers.js version:', ethers.version);
   
  const uploadSvg = async () => {
    setTxerror("");

    const svgBlob = new Blob([svgRef.current.outerHTML], {
      type: "image/svg+xml",
    });

    if (!(tokenCount > 0)) {
      setTxerror("Looks like you are not owning Cryptosher NFT. No worries! Share your story/poetry at sudipto@skiff.com !",);
      return;

    }
    /*
    const svgUrl = URL.createObjectURL(svgBlob);
    const downloadLink = document.createElement("a");
    downloadLink.href = svgUrl;
    downloadLink.download = "dynamic.svg";
    downloadLink.click();

    URL.revokeObjectURL(svgUrl);*/



    if (/*content.name === null || content.name === '' ||*/ content.description === null || content.description === '') {
        setTxerror("Fill out both fields – your name and your masterpiece!",);  
      return;
    }

    if (svgBlob) {
        // image post
        // STEP 1: Upload image
        setMessage("Uploading content ....");
        const txid = await UploadImage(svgBlob, "image/svg+xml");

    }
    setMessage('')
    setContent({...content, name: "", description: "" });

   // setTxActive(false);
  };
  const handleChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    if ( name === "description" ) {
      setCharacterCount(value.length);
      setContent((prevalue) => ({
        ...prevalue, // Spread Operator
        [name]: (value.slice(0, 140 - 1)),
      }));
    }
    else {
      setContent((prevalue) => ({
        ...prevalue, // Spread Operator
        [name]: (value.slice(0, 25 - 1)),
      }));
    }
  };

  
/*
  const handleCaptureImage = () => {
    const postElement = postRef.current;

    if (!postElement) {
      console.error("Couldn't find the post element.");
      return;
    }

    html2canvas(postElement).then((canvas) => {
      const link = document.createElement("a");
      link.download = "image-post.png";
      link.href = canvas.toDataURL("image/png");
      link.click();
    });
  }; */
  const convertTextToTSpans = (text) => {
    const lines = text.split('\n');
    const newText = [];
  
    for (let i = 0; i < lines.length; i++) {
      const words = lines[i].split(' ');
      let line = '';
      let tspanElements = [];
  
      for (let j = 0; j < words.length; j++) {
        if (line.length + words[j].length <= 33) {
          line += (line ? ' ' : '') + words[j];
        } else {
          tspanElements.push(
            <tspan key={i + '-' + j} x="50%" dy="1.2em">
              {line}
            </tspan>
          );
          line = words[j];
        }
      }
  
      tspanElements.push(
        <tspan key={i + '-' + words.length} x="50%" dy="1.2em">
          {line}
        </tspan>
      );
  
      newText.push(tspanElements);
    }
  
    return newText;
  };
  
  return (
    <div className="bg-white max-w-6xl w-11/12 mb-6 mx-auto">
      <Header title="" subtitle="Post" />
      <p className="hidden text-sm text-slate-600">
        {
          "Cryptosher – where poetry meets programming, and code flows like rhymes."
        }
      </p>

      <div className="rounded-xl mt-10 bg-slate-300 flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
        <div className="flex h-1/2 w-1/2 transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl">            
        <svg
        ref={svgRef}
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMinYMin meet"
        viewBox="0 0 350 350"
      >
        <defs>
          <linearGradient id="myGradient" gradientTransform="rotate(90)">
            <stop offset="5%" stop-color="#1e293b" />
            <stop offset="95%" stop-color="#0f172a" />
          </linearGradient>
        </defs>
        <style>{svgStyles}</style>
        <rect width="100%" height="100%" fill="url(#myGradient)" />
        <text
          x="10"
          y="20"
          className="base top"
          dominantBaseline="middle"
          textAnchor="start"
        >
          Cryotosher
        </text>
        <text
          x="95%"
          y="20"
          className="base bottom"
          dominantBaseline="middle"
          textAnchor="end"
        >
              {`${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getFullYear()}`}
        </text>
        <text
          x="50%"
          y="30%"
          className="base"
          dominantBaseline="middle"
          textAnchor="middle"
        >
          {convertTextToTSpans(content.description)}
            </text>
            {content.name &&
              <text
                x="50%"
                y="95%"
                className="base"
                dominantBaseline="middle"
                textAnchor="middle"
              >
                by {content.name}
              </text>
            }
      </svg>
        </div>
      </div>
      {txError && (
                            <p className="pt-5 text-[#FF6257] flex justify-start font-medium">
                                {txError}
                            </p>
                        )}
      <div className="text-slate-900 my-8 ">
        <div className="hidden">Name*</div>

        <div className="mt-3 text-sm text-slate-500">
          <input
            type="text"
            name="name"
            value={content.name}
            onChange={handleChange}
            className="pl-3 block w-full rounded-md border-0 py-1.5 text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-slate-300 text-sm sm:leading-6"
            placeholder="Your name"
          />
        </div>

        <div className="mt-3 hidden">Message*</div>
        <div className="mt-3 text-sm text-slate-500">
          <textarea
            name="description"
            rows="3"
            onChange={handleChange}
            value={content.description}
            className="pl-3 block w-full rounded-md border-0 py-1.5 text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:outline-0 focus:ring-2 focus:ring-inset focus:ring-slate-300 text-sm sm:leading-6"
            placeholder="Write a short story or poetry."
          ></textarea>
                  <div className="py-2 text-xs">{characterCount}/{240}</div>
        </div>
      </div>
      {
  !isConnected ? (
    <Wallet title={'Connect to Post'} />
  ) : chain.id !== config.chain.id ? (
    <button
      onClick={() => switchNetwork?.(config.chain.id)}
      className="bg-[#8247E5] mx-auto lg:mx-0 hover:none text-white font-semibold rounded-md py-2 px-4 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
      {'Switch Network'}
    </button>
  ) : (
    <button
      onClick={uploadSvg}
      className={`${
        message ? "bg-[#16191f3d]" : "bg-[#8247E5]"
      } mx-auto lg:mx-0 hover:none text-white font-semibold rounded-md py-2 px-4 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out`}
    >
      {message ? message : 'Post'}
    </button>
  )
}
    
    </div>
    
  );
};

export default Post;

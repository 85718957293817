//import { gql } from "apollo-boost";
import { gql } from "@apollo/client";


// query a variable number of pngs
export const GET_PNG = gql`
	query GetPNGs($numImages: Int) {
		transactions(
			limit: $numImages
			tags: [
				{ name: "Content-Type", values: ["image/png", "image/jpg", "image/svg+xml"]} 
				{ name: "application-id", values: ["cryptosher"]}
				   ]
				   order: DESC
		) {
			edges {
				node {
					id
				}
			}
		}
	}
`;

// get exactly 10 pngs
export const GET_PNG_10 = gql`
	query GetPNGs {
		transactions(
			limit: 10
			tags: [
				{ name: "Content-Type", values: ["image/png", "image/jpg", "image/svg+xml"]} 
				{ name: "application-id", values: ["cryptosher"]}
				   ]
				   order: DESC
		) {
			edges {
				node {
					id
				}
			}
		}
	}
`;

export const getByOwner_PNG = gql`
	query GetPNGs($numImages: Int) {
		transactions(
			first: $numImages
			tags: { name: "Content-Type", values: ["image/svg+xml"] }
			tags: [{ name: "Content-Type", values: ["image/svg+xml", "image/jpg"] }]
		) {
			edges {
				node {
					id
				}
			}
		}
	}
`;

// get exactly 10 pngs
export const getByOwner_PNG_10 = gql`
	query GetPNGs {
		transactions(
			first: 10
			owners: ["0x9Bb48d349894257aaCbdFD1675093aB689e10C78"]
			tags: [{ name: "Content-Type", values: ["image/svg+xml", "image/jpg"] }]
		) {
			edges {
				node {
					id
				}
			}
		}
	}
	
`;
export const getcount_PNG = gql`
query GetPNGs {
	transactions(
	  tags: [
		{ name: "Content-Type", values: ["image/png", "image/jpg", "image/svg+xml"] }
		{ name: "application-id", values: ["cryptosher"] }
	  ]
	) {
	  totalCount
	  edges {
		node {
		  id
		}
	  }
	}
  }
  `;

import { GetBundlr } from "./get-bundlr";
 
/**
 * Funds a Bundlr node the specified amount.
 * Note: The currency (token) used is the currency specified when
 * creating the Bundlr object in the file `get-bundlr.js`.
 *
 * @param {*} fundAmount About to fund, value in standard units. Value will automatically
 * be coverted to atomic units.
 * @returns "Node funded" if successful or an error message.
 */
export const FundNode = async (fundAmount) => {
	try {
		// get a refernce to the WebBundlr singleton
		const bundlr = await GetBundlr();
 
		const fundAmountAtomic = bundlr.utils.toAtomic(fundAmount);
		const tx = await bundlr.fund(fundAmountAtomic);
		return { success: true, message: "Node funded", transaction: tx };
	} catch (e) {
		console.log("Error on fund ", e);
		return { success: false, message: "Error on withdraw", error: e };
	}
};
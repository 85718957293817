import Navbar from "./Navbar";

const Header = ({ title, subtitle }) => {
    return (
        <>
        <div className="hidden justify-between pt-6 py-6 lg:flex">
            <div className="">
                <div className="flex flex-col cursor-pointer justify-center items-center">
                    <a href="/" className="relative my-auto hidden">
                        <h1 className="font-carter text-[#ff006a] leading-relaxed font-bold text-3xl text-center">
                            Cryptosher
                        </h1>                    </a>
                    <div className="h-6 flex pl-2 relative my-auto">
                        <a href="#" className="hidden">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" className="h-8 w-8 fill-slate-400 group-hover:fill-slate-600" viewBox="0 0 256 256"><path d="M223.68,66.15,135.68,18a15.88,15.88,0,0,0-15.36,0l-88,48.17a16,16,0,0,0-8.32,14v95.64a16,16,0,0,0,8.32,14l88,48.17a15.88,15.88,0,0,0,15.36,0l88-48.17a16,16,0,0,0,8.32-14V80.18A16,16,0,0,0,223.68,66.15ZM128,168a40,40,0,1,1,40-40A40,40,0,0,1,128,168Z"></path></svg>
                        </a>
                        <h1 className="text-slate-900 text-xl leading-7 m-auto">
                            <span className="font-carter text-[#ff006a] leading-relaxed font-bold text-2xl leading-6">Cryptosher</span>
                            <span className="text-base leading-6 text-[#80818a] font-extralight ml-2">{subtitle}</span>
                        </h1>
                    </div>
                </div>
            </div>

            <nav className="">
                <Navbar />
            </nav>
            </div>
            <nav className="lg:hidden flex flex-1 justify-center pt-6">
                <Navbar />
            </nav>
            <div className="lg:hidden py-6">
                <div className="flex flex-col cursor-pointer justify-center items-center">
                    <a href="/" className="relative my-auto hidden">
                        <h1 className="font-carter text-[#ff006a] leading-relaxed font-bold text-3xl text-center">
                            Cryptosher
                        </h1>                    </a>
                    <div className="h-6 flex pl-2 relative my-auto">
                        <a href="#" className="hidden">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" className="h-8 w-8 fill-slate-400 group-hover:fill-slate-600" viewBox="0 0 256 256"><path d="M223.68,66.15,135.68,18a15.88,15.88,0,0,0-15.36,0l-88,48.17a16,16,0,0,0-8.32,14v95.64a16,16,0,0,0,8.32,14l88,48.17a15.88,15.88,0,0,0,15.36,0l88-48.17a16,16,0,0,0,8.32-14V80.18A16,16,0,0,0,223.68,66.15ZM128,168a40,40,0,1,1,40-40A40,40,0,0,1,128,168Z"></path></svg>
                        </a>
                        <h1 className="text-slate-900 text-xl leading-7 m-auto">
                            <span className="font-carter text-[#ff006a] leading-relaxed font-bold text-2xl leading-6">Cryptosher</span>
                            <span className="text-base leading-6 text-[#80818a] font-extralight ml-2">{subtitle}</span>
                        </h1>
                    </div>
                </div>
            </div>
         <h1 className="text-slate-900 block text-[2em] font-bold my-3">
         {title}
     </h1>
        </>
    );
}

export default Header;
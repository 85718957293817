
const Instruction = ({ title, subtitle }) => {
    return (
        <div className="text-slate-900 my-8 ">
            <div>{title}</div>
            <div className="mt-3 text-sm text-slate-500">{subtitle}</div>
        </div>
    );
}

export default Instruction;
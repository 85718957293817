export default function Details() {
    return (
        <>

            <svg className="max-w-full" height="130" viewBox="0 0 1428 130" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M-2 0C88.7283 0.927528 145.913 27.1879 289.91 59.9119C385.908 81.7279 541.605 89.3348 757 82.7326C467.336 156.254 214.336 153.668 -2 74.9733" fill="#a3e635" />
                <path d="M98 104.708C275.413 72.2346 424.148 52.5247 544.204 45.5787C664.259 38.6328 808.525 41.7979 977 55.0742C929.07 56.1225 808.303 74.8455 614.7 111.243C421.097 147.641 248.863 145.463 98 104.708Z" fill="#84cc16" />
                <path d="M1044 51.6521C1128.83 29.3288 1277.08 17.6079 1437 40.1657V120C1269.17 77.9435 1138.17 55.1609 1044 51.6521Z" fill="#65a30d" />
                <path d="M-3.543 66.035C53.086 85.198 94.208 97.809 119.822 103.865C177.454 117.495 230.295 122.29 268.033 125.459C307.355 128.759 392.635 127.801 457.025 123.663C482.76 122.01 514.727 118.372 552.926 112.752C591.747 106.596 618.372 102.008 632.799 98.991C659.913 93.324 708.501 81.503 723.605 78.128C776.47 66.317 814.839 54.532 852.324 47.904C918.689 36.169 951.676 34.522 1007.19 32.432C1056.71 33.477 1093.39 35.129 1117.24 37.387C1157.7 41.219 1204.62 49.821 1231.4 54.304C1281.85 62.748 1350.35 79.432 1436.89 104.354L1437.19 136.352L-2.879 136.031L-3.543 66.035Z" fill="white" />
            </svg>

            <div className="max-w-3xl my-4 sm:my-8 mx-auto w-full">
                <h2
                    className="mb-6 text-3xl font-extrabold tracking-tight text-center text-slate-900 lg:mb-8 lg:text-3xl">
                    A smart contract that writes like Gulzar
                </h2>
                <p className="w-11/12 mx-auto text-center px-2 font-medium text-xl">
                    How is a Cryptosher generated?
                </p>

                <div className="mb-6 flex items-center justify-between px-4 py-4 sm:px-6">
                    <span className="w-9/10 pr-8 space-y-3">
                        <h2 className="text-2xl font-semibold">
                            Step 1
                        </h2>
                        <p className="text-lg">
                            Read the following list of <strong className="italic">bhaari</strong> words.

                        </p>
                    </span>
                </div>
                <table className="mx-auto">
                    <tbody>
                        <tr className=" text-lg border-b ">
                            <td className="font-normal px-6 py-4">Type of Oscillation
                            </td>
                            <td className="font-normal px-6 py-4">
                                <div className="flex flex-wrap italic"><div className="mr-3 text-lg font-medium">Thiraktey</div> <div className="mr-3 text-lg font-medium">Simat-tey</div> <div className="mr-3 text-lg font-medium">Jhulastey</div> </div>
                            </td>
                        </tr>
                        <tr className=" text-lg border-b ">
                            <td className="font-normal px-6 py-4">Facial Feature
                            </td>
                            <td className="font-normal px-6 py-4">
                                <div className="flex flex-wrap italic"><div className="mr-3 text-lg font-medium">Nazron</div> <div className="mr-3 text-lg font-medium">Hothon</div> <div className="mr-3 text-lg font-medium">Nainon</div> <div className="mr-3 text-lg font-medium">Gaalon</div> <div className="mr-3 text-lg font-medium">Mathey</div>


                                </div>
                            </td>
                        </tr>
                        <tr className=" text-lg border-b ">
                            <td className="font-normal px-6 py-4">Natural Phenomena
                            </td>
                            <td className="font-normal px-6 py-4">
                                <div className="flex flex-wrap italic"><div className="mr-3 text-lg font-medium">Boond</div>
                                    <div className="mr-3 text-lg font-medium">Oas</div> <div className="mr-3 text-lg font-medium">Barish</div> <div className="mr-3 text-lg font-medium">Hawa</div>

                                </div>
                            </td>
                        </tr>
                        <tr className=" text-lg border-b ">
                            <td className="font-normal px-6 py-4">Things that happen in the bathroom
                            </td>
                            <td className="font-normal px-6 py-4">
                                <div className="flex flex-wrap italic"><div className="mr-3 text-lg font-medium">Phisalna</div> <div className="mr-3 text-lg font-medium">Girna</div> <div className="mr-3 text-lg font-medium">Tapakna</div> <div className="mr-3 text-lg font-medium">Ulajhna</div> <div className="mr-3 text-lg font-medium">Sulajhna</div> <div className="mr-3 text-lg font-medium">Nikalna</div> <div className="mr-3 text-lg font-medium">Sawarna</div> </div>
                            </td>
                        </tr>
                        <tr className=" text-lg border-b ">
                            <td className="font-normal px-6 py-4">Random Emotion
                            </td>
                            <td className="font-normal px-6 py-4">
                                <div className="flex flex-wrap italic">
                                    <div className="mr-3 text-lg font-medium">Yaad</div> <div className="mr-3 text-lg font-medium">Darata</div>
                                    <div className="mr-3 text-lg font-medium">Hasata</div> <div className="mr-3 text-lg font-medium">Rulata</div>
                                </div>
                            </td>
                        </tr>
                        <tr className=" text-lg border-b ">
                            <td className="font-normal px-6 py-4">Movement of a UFO
                            </td>
                            <td className="font-normal px-6 py-4">
                                <div className="flex flex-wrap italic"><div className="mr-3 text-lg font-medium">Duur jana
                                </div> <div className="mr-3 text-lg font-medium">Paas aana
                                    </div> <div className="mr-3 text-lg font-medium">Roshni phailana
                                    </div> <div className="mr-3 text-lg font-medium">Gumm ho jaana
                                    </div> </div>
                            </td>
                        </tr>
                    </tbody>
                </table>


                <div className="my-6 flex items-center justify-between px-4 py-4 sm:px-6">
                    <span className="w-9/10 pr-8 space-y-3">
                        <h2 className="text-2xl font-semibold">
                            Step 2
                        </h2>
                        <p className="text-lg">
                            Insert the relevant section, into the sentence below.

                        </p>
                    </span>
                </div>

                <div className="text-lg italic mb-6 flex items-center justify-between border border-grey-lighter mx-2 px-4 py-4 sm:px-6">
                    <span className="w-9/10 pr-8">
                        <p className="">
                            [type of oscillation] <strong>hue</strong> [facial expression] <strong>se</strong>
                            [natural phenomena] <strong>ka</strong> [things that
                            happen in the bathroom]
                        </p>
                        <p className="">
                            [random emotion] <strong>hai mujhe tera wahi</strong> [movement of a UFO]
                        </p>
                        <p className="mt-7 italic ">
                            Example: Thiraktey hue gaalon se boond ka ulajhna. Rulata hai mujhe tera wahi gumm ho
                            jana
                        </p>
                    </span>

                </div>
                <p className="mt-7 italic px-3">
                    PS: No offence intended to the great shayar and his fans
                </p>
            </div>
        </>
    )
}